@import '../node_modules/@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@import '../node_modules/quill/dist/quill.core.css';
@import '../node_modules/quill/dist/quill.bubble.css';
@import '../node_modules/quill/dist/quill.snow.css';
@import '../node_modules/quill-emoji/dist/quill-emoji.css';
@import '../node_modules/@ctrl/ngx-emoji-mart/picker.css';
@import './theme.scss';
@import './function.scss';

@import "billboard.js/dist/theme/insight.css"; //billboard theme css file
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

:root {
  /* Colors */
  --fontface:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --fontColor: #555;
  --theme-color1:#00235E;
  --theme-color2: #00235E;
  --headerColor: #fff;
  --headerBackground: #00235E;
  --LogofontColor:#fff;
  --LogospanColor:#fff;
  --navColor: #00235E;
  --navBackground: #fff;
  --navactiveColor: #00235E;
  --navactiveBackground: #d9d9d9;
  --sidebarColor:#00235E;
  --sidebarBackground:#fff;
  --sidebarActiveColor:#00235E;
  --sidebarActiveBackground:#d9d9d9;
  --buttonprimaryColor: #FFF;
  --buttonprimaryBackground: #1f4160;
  --buttonprimaryactiveColor: #FFF;
  --buttonprimaryactiveBackground: #555;
  --buttonsecondaryactiveColor: #FFF;
  --buttonsecondaryactiveBackground: #00235E;
  --buttonsecondaryColor: #FFF;
  --buttonsecondaryBackground: #555;
  --tableheadColor: #555;
  --tableheadBackground: #fff;
  --tablealternateBackground: #dde3f4;
  --footerColor: #fff;
  --footerBackground: #555;
  --white-color: #fff;
  --sidebar-display:block;
  --menubar-display:none;
  
  // --logoclor:#fff;
  // --header-bg-color: #555;
  // --menu-bg-color:#fff;
 

  // --theme-border-color1: #c29c51;
  // --theme-border-color2: #555;
  
  // --font-color:#555;


}

html,body{
  min-height:100%;
}

body {
  margin: 0;
  overflow-x:hidden;
  overflow-y:auto;
  background-color:#f1f3f6;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--font-color);

}

.wrapper{
  min-height:100%;
  position:relative;

}
.wrapper:before,.wrapper:after{
  display:table;
}
.wrapper:after{
  clear:both;
}
.label-icon{
  background-color: #00235E;
}
body.hold-transition .content-wrapper,body.hold-transition .right-side,body.hold-transition .main-sidebar,body.hold-transition .left-side,body.hold-transition .main-header .navbar,body.hold-transition .main-header .logo{
  -webkit-transition:none;
  transition:none;
}
.content-wrapper,.right-side,.main-footer{
  -webkit-transition:-webkit-transform .3s ease-in-out,margin .3s ease-in-out;
  -webkit-transition:margin .3s ease-in-out,-webkit-transform .3s ease-in-out;
  transition:margin .3s ease-in-out,-webkit-transform .3s ease-in-out;
  transition:transform .3s ease-in-out,margin .3s ease-in-out;
  transition:transform .3s ease-in-out,margin .3s ease-in-out,-webkit-transform .3s ease-in-out;
  margin-left:250px;
  z-index:820
}

.content-wrapper .main-footer{
  margin-left:0
}
@media (max-width: 767px){
  .content-wrapper,.right-side,.main-footer{
      margin-left:0!important
  }
}
@media (min-width: 768px){
  .sidebar-collapse .content-wrapper,.sidebar-collapse .right-side,.sidebar-collapse .main-footer{
      margin-left:0
  }
}
@media (max-width: 767px){
  .sidebar-open .content-wrapper,.sidebar-open .right-side,.sidebar-open .main-footer{
      -webkit-transform:translate(250px,0);
      transform:translate(250px,0)
  }
}
.content-wrapper,.right-side{
  min-height:100%;
  z-index:800;
  background-color:#f1f3f6
}
.main-footer{
  background:#f8f4f4;
  padding:15px;
  color:var(--theme-color1);
  border-top:1px solid #d2d6de;
  text-align:center
}

.fixed .main-header,.fixed .main-sidebar,.fixed .left-side{
  position:fixed
}
.fixed .main-header{
  top:0;
  right:0;
  left:0
}
.fixed .content-wrapper,.fixed .right-side{
  padding-top:60px
}
@media (max-width: 767px){
  .fixed .content-wrapper,.fixed .right-side{
      padding-top:120px
  }
}
.fixed.layout-boxed .wrapper{
  max-width:100%
}



body.hold-transition .content-wrapper,body.hold-transition .right-side,body.hold-transition .main-footer,body.hold-transition .main-sidebar,body.hold-transition .left-side,body.hold-transition .main-header .navbar,body.hold-transition .main-header .logo{
  -webkit-transition:none;
  transition:none
}
.content{
  min-height: 90vh; /* opera */
  margin-right:auto;
  margin-left:auto;
  margin-top: 7px;
  padding:0 30px 10px
}
@media (max-width: 767px){
  .content{
      padding:0 15px 10px
  }
}

/* Header css starts here*/
.main-header{
  position:relative;
  max-height:120px;
  z-index:1030;
  background-color:var(--headerBackground);
  border-bottom:1px solid #fff;
  display: flex;
  flex-direction: row;
  
 
}

.main-header .logo {
  width: 250px;
}
.main-header .logo  img{
  width:60px;
  height: 60px;
}
.main-header .logo a{
  color: var(--LogofontColor);
  font-size: 30px;
  font-weight: 700;
  text-decoration: none;
 
}

.main-header .logo a:hover{
text-decoration: none;
}

.main-header .navbar-top{
flex-grow:1;
justify-content:space-between;
}


.main-header .navbar-top .sidebar-toggle{
  color: var(--LogofontColor);
  font-size:28px;
  line-height:20px;
  cursor:pointer;
  margin-left: 75px;
}
.main-header .navbar-top .nav .log-user-info{
  color: var(--LogofontColor) !important;
  font-size: 16px;
  line-height: 1.2;
}

.main-header .navbar-top .nav .log-user-info span{
  font-size: 12px;
}
.main-header .navbar{
 padding:0 !important;
}

.main-header .navbar-top .nav > li {
  margin-right:7px;
  vertical-align: middle;
  }

.main-header .navbar-top .nav > li >.nav-link {
  padding: 0.5rem 0.2rem;
  color:#fff;
  padding:3px 3px;
  font-size: 16px;
}


.main-header .navbar-top .nav > li > a>i{
  padding:3px 3px;
  text-align:center;
  color: #ccc;
  font-size:22px;
}



.main-header .navbar-top .nav > li > .dropdown-menu{
 right: 0px;
  left: auto;
  border:1px solid #d9d9d9;
  background:#fff;
  background-color: var(--sidebarBackground);
  color: var(--sidebarColor);
  border-radius: none;
  min-width: 250px;

}


.main-header .profile-menu > .dropdown-menu{
  width:280px;
  padding:0;
  margin:0;
  top:100%;
}


.main-header .profile-menu > .dropdown-menu li{
  border-bottom: 1px groove #ccc;
}
.main-header .profile-menu > .dropdown-menu li a{
  padding:12px 5px 12px 15px;
  color: var(--sidebarColor);
   font-size:14px;
  font-weight:500;
}

.main-header .profile-menu > .dropdown-menu li:hover > a{
  background: var(--sidebarActiveBackground) !important;
  color: var(--sidebarActiveColor) !important;
  text-decoration: none;
}






// .main-header .navbar-top .nav > .notifications-menu > .dropdown-menu{
//   width:280px;
//   padding:0;
//   margin:0;
//   top:100%;
// }
// .main-header .navbar-top .nav > .notifications-menu > .dropdown-menu > li{
//   position:relative;
//   background:#e7e7e7;
// }

// .main-header .navbar-top .nav > .notifications-menu > .dropdown-menu >.header{
//   background-color:var(--headerBackground);
//   // margin: 2px 2px;
//    padding:8px 10px;
//    border-top-right-radius: 5px;
//    border-top-left-radius: 5px;

//   border-bottom:1px solid #e1e6ef;
//   color:var(--headerColor);
//   font-size:14px;
// }
// .main-header .navbar-top .nav > .notifications-menu > .dropdown-menu > .footer {
//   background-color:var(--headerBackground);
//   padding:7px 10px;
//   border-top:1px solid #e1e6ef;
//   text-align:center
// }

// .main-header .navbar-top .nav > .notifications-menu > .dropdown-menu > .footer> a{
//   color:var(--headerColor);
//   font-size:12px;
//   display: block;
//   padding:0 !important;
// }


// .main-header .navbar-top .nav > .notifications-menu > .dropdown-menu > li.footer > a:hover{
//   text-decoration:none;

// }
// .main-header .navbar-top .nav > .notifications-menu > .dropdown-menu > li .menu{
//   max-height:200px;
//   margin:0;
//   padding:0;
//   list-style:none;
//   overflow-x:hidden;
// }
// .main-header .navbar-top .nav > .notifications-menu > .dropdown-menu > li .menu > li > a{
//   display:block;
//   white-space:nowrap;
//   border-bottom:1px solid #f4f4f4;
// }
// .main-header .navbar-top .nav > .notifications-menu > .dropdown-menu > li .menu > li > a:hover{
//   background:#f4f4f4;
//   text-decoration:none;
// }
// .main-header .navbar-top .nav > .notifications-menu > .dropdown-menu > li .menu > li > a{
//   color:#444;
//   overflow:hidden;
//   text-overflow:ellipsis;
//   padding:10px;
//   font-size:13px;
// }
// .main-header .navbar-top .nav > .notifications-menu > .dropdown-menu > li .menu > li > a > i{
//   width:20px;
//   font-size:16px;
//   margin-right:10px;
// }

// .main-header .navbar-top .nav > .notifications-menu > .dropdown-menu > li .menu > li > a > .badge{
//   width: 20px ;height:20px; border-radius: 50%;
//   padding-top: 4px;
//   font-size: 12px;
// }

.main-header .navbar-top .nav > li > .dropdown-menu li{
  border-bottom: 1px groove #ccc;
}
.main-header .navbar-top .nav > li > .dropdown-menu li a{
  padding:12px 5px 12px 15px;
  color: var(--sidebarColor);
   font-size:14px;
  font-weight:500;
}

.main-header .navbar-top .nav > li > .dropdown-menu li:hover > a{
  background: var(--sidebarActiveBackground) !important;
  color: var(--sidebarActiveColor) !important;
  text-decoration: none;
}




@media (max-width: 768px){
  .main-header{
   flex-direction:column;
   max-height:160px;
   
  }
 .main-header .logo a{text-align: center; justify-content: center;}
 .main-header .navbar-top {
  border-top: 1px solid goldenrod;
}

.main-header .navbar-top .nav{
  margin-right: 0px;
}
.main-header .navbar-top .sidebar-toggle{
  font-size:24px;
  margin-left:15px;
}
.main-header .navbar-top .nav > li,.main-header .navbar-top .nav > li> a{
  margin-right: 10px !important;
  padding: 0 !important;
}


.main-header .navbar-top .nav > li > a>i{
 font-size:20px;
}
.main-header .navbar-top .nav > li > a> img{
  width:32px;
  height:32px;
}


 .main-header .navbar-top .navbar-nav > li > .dropdown-menu{
  position: absolute;
   right: 0px;
   left: auto;
   border:1px solid #d9d9d9;
   background:#fff;
   color: var(--sidebarColor);
   border-radius: none;
}


}

@media (max-width: 480px){
  .main-header{
   flex-direction:column;
   max-height:160px;
   }

 .main-header .logo{text-align: center;}
   .main-header .logo .logo-large img{
      width:50px;
      height: 50px;
  }
  .main-header .logo a{
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      text-decoration: none;
  }

 .main-header .navbar-top {
      border-top: 1px solid goldenrod;
  }

.main-header .navbar-top .nav{
  margin-right: 0px;
}
.main-header .navbar-top .sidebar-toggle{
  font-size:24px;
}
.main-header .navbar-top .nav > li,.main-header .navbar-top .nav > li> a{
  margin-right: 10px !important;
  padding: 0 !important;
}


.main-header .navbar-top .nav > li > a>i{
 font-size:20px;
}
.main-header .navbar-top .nav > li > a> img{
  width:32px;
  height:32px;
}



}




.main-sidebar{
  position:absolute;
  top:0;
  left:0;
  padding-top:70px;
  min-height:100%;
  height: 100vh;
  width:250px;
  z-index:810;
  background-color: var(--sidebarBackground);
  -webkit-transition:-webkit-transform .3s ease-in-out,width .3s ease-in-out;
  -webkit-transition:width .3s ease-in-out,-webkit-transform .3s ease-in-out;
  transition:width .3s ease-in-out,-webkit-transform .3s ease-in-out;
  transition:transform .3s ease-in-out,width .3s ease-in-out;
  transition:transform .3s ease-in-out,width .3s ease-in-out,-webkit-transform .3s ease-in-out;
}

@media (max-width: 767px){
  .main-sidebar,.left-side{
      padding-top:120px;
  }
}
@media (max-width: 767px){
  .main-sidebar,.left-side{
      -webkit-transform:translate(-250px,0);
      transform:translate(-250px,0);
  }
}
@media (min-width: 768px){
  .sidebar-collapse .main-sidebar,.sidebar-collapse .left-side{
      -webkit-transform:translate(-230px,0);
      transform:translate(-230px,0);
  }
}
@media (max-width: 767px){
  .sidebar-open .main-sidebar,.sidebar-open .left-side{
      -webkit-transform:translate(0,0);
      transform:translate(0,0);
  }
}






.sidebar-menu{
  list-style:none;
  margin-top:2px;
  padding:0;
  background: var(--sidebarBackground);
}
.sidebar-menu > li{
  position:relative;
  margin:0;
  padding:0;
  border-bottom: 1px groove #ccc;
}

.sidebar-menu > li > a{
  padding:12px 5px 12px 15px;
  display:block;
  color: var(--sidebarColor);
  position:relative;
  font-size:16px;
  font-weight:500;
  letter-spacing:.3px;
}

.sidebar-menu > li > a > i{
  margin-right:10px;
  display:inline-block;
}
.sidebar-menu > li.active > a:before{
  background: var(--sidebarBackground);
  content:"";
  height:100%;
  left:0;
  position:absolute;
  top:0;
  width:3px;
  color: var(--sidebarColor);
}

.sidebar-menu > li:hover > a{
  background: var(--sidebarActiveBackground);
  color: var(--sidebarActiveColor);
  text-decoration: none;
}

.active-parent-menu{
  background: var(--headerBackground)!important;
  color: var(--headerColor)!important;
  text-decoration: none;
}
.submenu-active-parent-menu>.active{
  background: var(--sidebarActiveBackground);
  color: var(--sidebarActiveColor);
  text-decoration: none;
}


.sidebar-menu > li > a > .fa,.sidebar-menu > li > a > .glyphicon,.sidebar-menu > li > a > .ion{
  width:24px;
}
.sidebar-menu > li .label,.sidebar-menu > li .badge{
  margin-right:5px;
  
}
.sidebar-menu > li .badge{
  margin-top:3px;
}
.sidebar-menu li.header{
  padding:10px 25px 10px 15px;
  font-size:12px;
  color:#fff;
}
.sidebar-menu li > a > .fa-angle-right,.sidebar-menu li > a > .pull-right-container > .fa-angle-right{
  width:auto;
  height:auto;
  padding:0;
  margin-right:10px;
}
.sidebar-menu li::after>a>.fa-angle-right{
  -webkit-transform:rotate(-90deg);
  transform:rotate(-90deg);
}

.sidebar-menu li.active > a > .fa-angle-right,.sidebar-menu li.active > a > .pull-right-container > .fa-angle-right{
  -webkit-transform:rotate(90deg);
  transform:rotate(90deg);
}

.sidebar-menu li.active > .treeview-menu{
  display:block
}
.sidebar-menu .treeview-menu{
  position:relative;
  list-style:none;
  padding:5px 0 10px;
  margin:0;
  display: none;
  padding-left:35px;
  background-color: var(--sidebarBackground);
}
.sidebar-menu .treeview-menu:before{
  width:2px;
  bottom:0;
  background:rgba(59,70,72,0.5);
  content:"";
  position:absolute;
  top:0;
  left:33px;
}
.sidebar-menu .treeview-menu .treeview-menu{
  padding-left:20px;
}

.sidebar-menu .treeview-menu > li{
  margin:0;
  position:relative;
}
.sidebar-menu .treeview-menu > li > a{
  padding:5px 5px 5px 20px;
  display:block;
  color: var(--sidebarColor);
  letter-spacing:.3px;
}
.sidebar-menu .treeview-menu li.disabled a{
  cursor:not-allowed;
  filter:alpha(opacity=65);
  -webkit-box-shadow:none;
  box-shadow:none;
  opacity:.65;
}
.sidebar-menu .treeview-menu > li:hover > a{
  color:var(--headerColor) !important;
  background-color: var(--headerBackground) !important;
  text-decoration: none;


}
.active-tree-menu{
  color:var(--headerColor) !important;
  background-color: var(--headerBackground) !important;
  text-decoration: none;
}




.sidebar-menu .treeview-menu > li::before{
  left:0;
  top:13px;
  width:15px;
  content:' ';
  position:absolute;
  display:inline-block;
  border:1px solid rgba(59,70,72,0.5);
}

.sidebar-menu .treeview-menu > li > a > .fa,.sidebar-menu .treeview-menu > li > a > .glyphicon,.sidebar-menu .treeview-menu > li > a > .ion{
  width:20px;
}
.sidebar-menu .treeview-menu > li > a > .pull-right-container > .fa-angle-right,.sidebar-menu .treeview-menu > li > a > .pull-right-container > .fa-angle-down,.sidebar-menu .treeview-menu > li > a > .fa-angle-right,.sidebar-menu .treeview-menu > li > a > .fa-angle-down{
  width:auto;
}

.sidebar-menu,.main-sidebar .user-panel,.sidebar-menu > li.header{
  white-space:nowrap;
  overflow:hidden
}
.sidebar-menu:hover{
  overflow:visible
}
.sidebar-menu > li.header{
  letter-spacing:1px;
  font-weight:600;
  color:#a6a6a6
}
.sidebar-menu li > a > .pull-right-container{
  position:absolute;
  right:10px;
  top:50%;
  margin-top:-7px
}



@media (min-width: 768px){
  .sidebar-mini.sidebar-collapse .content-wrapper,.sidebar-mini.sidebar-collapse .right-side,.sidebar-mini.sidebar-collapse .main-footer{
    margin-left:50px!important;
    z-index:840
}
  .sidebar-mini.sidebar-collapse .main-sidebar{
      -webkit-transform:translate(0,0);
      transform:translate(0,0);
      width:50px!important;
      z-index:850;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li{
      position:relative;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a{
      margin-right:0;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span{
      border-top-right-radius:4px;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:not(.treeview) > a > span{
      border-bottom-right-radius:4px;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu{
      padding-top:5px;
      padding-bottom:5px;
      border-bottom-right-radius:4px;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > span:not(.pull-right),.sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > .treeview-menu{
      display:block!important;
      position:absolute;
      width:230px;
      left:50px;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > span{
      top:0;
      margin-left:0;
      padding:12px 5px 12px 20px;
      background-color:inherit;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > .pull-right-container{
      float:right;
      width:auto!important;
      left:230px!important;
      top:10px!important
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > .pull-right-container > .label:not(:first-of-type){
      display:none;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > .treeview-menu{
      top:44px;
      margin-left:0;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar .user-panel > .info,.sidebar-mini.sidebar-collapse .sidebar-form,.sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span,.sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu,.sidebar-mini.sidebar-collapse .sidebar-menu > li > a > .pull-right,.sidebar-mini.sidebar-collapse .sidebar-menu li.header,.sidebar-mini.sidebar-collapse .sidebar-menu > li > a sup{
      display:none!important;
      -webkit-transform:translateZ(0);
  }

  .sidebar-mini.sidebar-collapse .main-header .logo > .logo-mini{
      display:block;
      margin-left:-15px;
      margin-right:-15px;
      font-size:18px;
  }
  .sidebar-mini.sidebar-collapse .main-header .logo > .logo-large{
      display:none;
  }
 
  .sidebar-mini.sidebar-collapse .review-btn-off,.sidebar-mini.sidebar-collapse .review-btn-on{
    padding: 1px 0px 0px 5px;
    height: 32px;
    line-height: 22px;
  }







}








h6{
  font-size: 14px !important;
  font-weight: 500;
}
table tr td div ul {
 
  
  padding-left: 16px !important;
  
}
.tooltip{
  font-size: 12px;
  font-style: italic;
  z-index: 2000;

}

.tooltip-right{
  font-size: 14px;
  font-style: italic;
  margin-left:30px;
  }



button {
  outline: none !important; // this one
}
button:focus {outline:0 !important;}



// chart axis label css billboard.js
.bb-axis-x-label, 
.bb-axis-y-label{ 
  font-weight: bold;
  stroke-width: 10px;
 

  
}



label{
 font-size: 14px !important;
  color:var(--font-color)!important;
  font-weight: 500!important;
}
.form-control-xs { 
  height: calc(1.5em + .375rem + 2px) !important;
   padding: .125rem .25rem !important;
    font-size: .75rem !important; 
    line-height: 1.5; border-radius: .2rem; 
    color:var(--fontColor)!important;
    font-weight: 400!important;
  }


 
  .form-control:focus {   
    border-color: var(--theme-color1) !important;
    box-shadow: var(--theme-color1) 0px 0px 0px 0px, 
    var(--theme-color1) 0px 0px 0px 0px, 
    var(--theme-color1) 0px 0px 0px 0px, 
    var(--theme-color1) 0px 0px 0px 1px, 
    var(--theme-color1) 0px 0px 0px 0px, 
    var(--theme-color1) 0px 0px 0px 0px, 
    var(--theme-color1) 0px 0px 0px 0px;
    outline: 0 none;
  }
  .form-control::placeholder{
    font-style: italic !important;
  }

  .form-control{
    font-size: 14px;
    line-height: 28px;
    padding: 4px 8px;
    width: 100%;
    min-height: 30px;
    border: unset;
    border-radius: 4px;
   background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px;
   }


 

.table thead{
  border: none !important;
}

  .table tr th{
   
    font-size: 12!important;
    vertical-align: middle !important;
    // min-width: 100px !important;
    font-weight: 500;
    color:var(--tableheadColor)!important;
    background-color: var(--tableheadBackground)!important;
    border-top: none !important;
}

.table tr td{
    padding:2px 5px 2px 5px !important;
    margin:0 !important;
    font-size: 12!important;
    vertical-align: middle !important;
    color:var(--font-color)!important;
    
}
.table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: var(--tablealternateBackground);
}



// /* width */
// ::-webkit-scrollbar {
//   width: 5px;
//   min-height : 100%;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: #eee; 
// }
 
// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background:var(--navactiveBackground) !important; 
//   min-height: 60px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: var(--theme-color2);; 
// }







.owl-dt-calendar-cell-selected{color:rgba(255,255,255,.85);background-color:var(--theme-color1)!important}

.card{
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  border: none;
 
}
.card-header{
  background-color: #fff!important;
}

/*start of css added by sidharth*/


div.dataTables_wrapper div.dataTables_length select {
  width: 60px !important;
  display: inline-block;
}
.input-group-sm>.input-group-btn>select.btn:not([size]):not([multiple]), .input-group-sm>select.form-control:not([size]):not([multiple]), .input-group-sm>select.input-group-addon:not([size]):not([multiple]), select.form-control-sm:not([size]):not([multiple]) {
  height: calc(2.2rem + 2px) !important;
  
}

// table th{
//   text-align: center!important;
// }

// .navbar-nav .nav-link {
//   color: #fff;
// }
// .navbar-nav .nav-link :active{
//   color: #c29c51;
// }


.fa-arrow-right{
  color: var(--theme-color1)!important;
  font-size: 18px;
}

.is-active{
  color: var(--navactiveColor) !important;
  background-color: var(--navactiveBackground) !important;
}

.submenu-active{
  background-color: var(--navactiveBackground) !important;
  color: var(--navactiveColor)!important;
}

.bg-gold-color{
  background-color: var(--theme-color1);
}


.cdk-overlay-container{
  z-index:2000!important;
}
.colorred{
  color: red;
  font-size: 11px;
}
option:hover,option:focus{background-color:var(--theme-color1); color: #fff;}

.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
}

.daterangepicker:before, .daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar {
  display: block;
}

.daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
  padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}

.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff;
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}

.daterangepicker .ranges li:hover {
  background-color: #eee;
}

.daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    width: 140px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges, .daterangepicker.single .drp-calendar {
    float: left;
  }

  .daterangepicker {
    direction: ltr;
    text-align: left;
  }

  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }

  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }

  .daterangepicker .ranges, .daterangepicker .drp-calendar {
    float: left;
  }
}

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }

  .daterangepicker .ranges {
    float: left;
  }

  .daterangepicker.rtl .ranges {
    float: right;
  }

  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}





.mandatory{
  color: red;
  font-size:11px !important;
  font-style: italic!important;
}


/*
*
* =====================
* SIDEBAR
* =====================
*
*/

.btn-custom-gold{
  background-color: var(--buttonprimaryBackground);
  color: var(--buttonprimaryColor) !important;
  border-radius: 0!important;
  font-weight: 500;
  font-size: 14px !important;
 cursor: pointer !important;
}

.btn-custom-gold:hover{
  background-color: var(--buttonprimaryactiveBackground);
  color: var(--buttonprimaryactiveColor);
  cursor: pointer !important;
}

.btn-custom-black{
  border-radius: 0!important;
  background-color: var(--buttonsecondaryBackground) !important;
  color: var(--buttonsecondaryColor) !important;
  font-weight: 500;
  font-size: 14px !important;
  cursor: pointer !important;
}


.btn-custom-black:hover{
  background-color: var(--buttonsecondaryactiveBackground) !important;
  color: var(--buttonsecondaryactiveColor) !important;
  cursor: pointer !important;
}

.btn-sm{
  padding: 5px 12px 5px 12px!important;
  font-size: 13px!important;
  cursor: pointer !important;
}




.white-icon{
  -webkit-filter: invert(100%);
  font-weight: 600;
}

.btn-light{
  background-color: #fff !important;
  color: #504e4e !important;
}



.btn-light:hover, .btn-light:focus, .btn-light:active, .btn-light.active, .open>.dropdown-toggle.btn-light {
  color: #504e4e !important;
  outline: none !important;
  
}

.showlogotext{
display: block;
}

.heidelogotext{
  display: none;
}





.overflow-hidden {
  overflow: hidden !important;
}

.form-control-label {
  font-size: 12px;
  color: #6c757d;
}

.pagination >a{
  color:#222222 !important;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color:    var(--theme-color1) !important; ; //background color of buttons. Blue by default
  border-color:        var(--theme-color1)!important; // color or borders. Also blue by default
  color:               #fff !important; // text color of buttons which is white by default
  cursor:pointer !important;
  z-index:2;
}

// Quill Rich Text Editor

quill-editor{
  display: flex !important;
  flex-direction: column-reverse !important;

}
quill-editor #emoji-palette{
  max-width: 400px;
  top:-225px !important;
}


#tab-panel{
  max-height: 400px !important;
  padding:8px;
}

#alertBox{
  position:fixed;
  z-index:1400;
  top:80%;
  right:4%;
  margin:0px auto;
  text-align:center;
  display:none;
}

#alertBox a{
  color : var(--buttonprimaryColor);
}

.notification-msg{
  position: relative;
  margin-left: 20px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: rgb(79, 156, 76);
  width: 200px;
  height: 50px;
  text-align: left;
  font: 400 .9em 'Open Sans', sans-serif;
  border: 1px solid rgb(79, 156, 76);
  border-radius: 10px;
  color: #FFF !important;
}



@media only screen and (max-width: 1024px) {
  body{
    font-size: 12px !important;
  }

  .menubar .nav-link i{
    display: block;
    text-align: center;
  }
  .menubar .nav-link,.dropdown-menu .dropdown-item ,.colorpallett{
   
     font-size: 12px;
     font-weight: 600 !important;
     border-right: none;
    
   
  }

  .form-control-xs,label,.form-control,.table tr td,.table tr th,.form-control-label,#sidemenu ul li a,.text-dark,
  .message1,.message{
    font-size: 12px !important;
    font-weight: 200 !important;
  }
  .message,.message1{
    padding:4px 3px;
  }
}


@media only screen and (max-width: 1280px) {
  body{
    font-size: 14px !important;
  }
  .menubar .nav-link,.colorpallett{
   
     font-size: 14px;
     font-weight: 500 !important;
  
  }
  .form-control-xs,label,.form-control,.form-control-label,#sidemenu ul li a,.text-dark{
    font-size: 14px;
     font-weight: 500 !important;
  }
  
  .message1,.message{
    font-size: 12px !important;
    font-weight: 200 !important;
  }
  .message,.message1{
    padding:4px 6px;
  }
}


#spinner{
  position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.594);
    z-index: 10000 !important;
}

#spinnertext{
  position: fixed;
  top:45%;
  font-size: 32px !important;
  width: 100%;
  height: 100%;
  z-index: 1000;
  text-align: center;
}

$animation-duration: 1.8s;

.clock {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 10px;
  background: #3498DB;
  border-radius: 5px;
  animation: load $animation-duration ease-in-out infinite; 
  &:before, &:after {
    position: absolute; 
    display: block;
    content: "";
    animation: load $animation-duration ease-in-out infinite;
    height: 10px;
    border-radius: 5px;
  }
    &:before {
    top: -20px;
    left: 10px;
    width: 40px;
    background: #EF4836;
  }
  &:after {
    bottom: -20px;
    width: 35px;
    background: #F5AB35; 
  }
}

@keyframes load {
  0% {
    transform: translateX(40px);
  }
  
  50% {
    transform: translateX(-30px);
  }
  100% {
    transform: translateX(40px);
  }
}



ul.pagination {
	font-size: 13px !important;
	margin:10px 0 !important;
}

div.dataTables_wrapper div.dataTables_info {
	font-size: 13px !important;
	margin:10px 0 !important;
}

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal !important;
  text-align: left;
  white-space: nowrap;
  font-size: 13px !important;
	margin:10px 0 !important;
}
.angularjs-datetime-picker {
  z-index:5000;
}

#EventInfoModel .modal-header a{
  font-size: 18px !important;
  font-weight: 600;
  cursor: pointer !important;
  color:rgb(126, 125, 125) !important;
}

#EventInfoMode .modal-content {
  border: none;
  border-radius: 2px;
      box-shadow: 0 16px 28px 0 rgba(0,0,0,0.22),0 25px 55px 0 rgba(0,0,0,0.21);
}
.meeting-info-heading{
  font-size: 16px!important;
  margin-top: -20px !important;
}
#EventInfoModel .tblmeetinginfo .meeting-heading{
  font-size: 16px !important;
  
 }

#EventInfoModel .tblmeetinginfo{
  margin-top: -15px !important;
}
#EventInfoModel .tblmeetinginfo tr td{
  font-size: 14px !important;
 }


 #EventInfoModel .modal-header .action-btn {
  width: 32px;
  height: 32px;
  color: #fff;
  outline: 0!important;

}

#EventInfoModel .modal-header .action-btn i{
  font-size: 18px;
  
}




 #EventInfoModel .modal-header .closebtn {
  width: 32px;
  height: 32px;
  background-color: var(--navactiveColor);
  color: #fff;
  border:1px solid var(--navactiveColor) !important;
  outline: 0!important;

}

#EventInfoModel .modal-header .closebtn i{
  font-size: 18px;
  
}

.tooltip.tooltip-top,
.tooltip.tooltip-bottom,
.tooltip.tooltip-left,
.tooltip.tooltip-right {
  z-index: 100000;
}


// .dataTables_scrollHeadInner, .table{
//   width:100%!important
//      };


.multiselect-dropdown > .dropdown-btn{
  font-size:12px !important;
  background-color: red !important;
}

#table_accomplishments .dataTables_filter label{
 
  display: none !important;
}

.swal2-popup.swal2-toast .swal2-close {
  /* grid-column: 3/3; */
  color:#fff!important;
  grid-column:1;
  grid-row: 1/99;
  align-self: center;
  width: 0.8em;
  height: 0.8em;
  margin: 0;
  font-size: 2em;
}


.swal2-backdrop-show {
  background:transparent !important;
}

.toatmessege .swal2-close{
  color:#fff!important;
  outline: 0 !important;
  border: none !important;
  box-shadow: none !important;

}

.toatmessege .swal2-html-container {
    margin: 1em 1.6em 0.3em !important;
    font-size: 18px !important;
    text-align: justify!important;
    padding-top: 24px !important;

}

.confirm-messege .swal2-close{
  color:#fff!important;
  box-shadow: none !important;
  outline: 0 !important;
}

.confirm-messege .swal2-confirm,.confirm-messege .swal2-cancel,.confirm-messege .swal2-deny{
  background-color: transparent !important;
  border:2px solid #fff !important;
  box-shadow: none !important;
  outline: 0 !important;

}

.multiselect-dropdown .dropdown-list{
  overflow-y: hidden !important;
}


// material Design CSS
.form-control-plaintext:focus,.form-control-plaintext:visited{
   outline: none !important;
}

.custom-mat-table{
  width: 100%;
  
}
.custom-mat-table .custom-header-cell{
  color:var(--tableheadColor)!important;
  font-weight:600!important;
  font-size: 14px;
}
.custom-mat-table .mdc-data-table__header-row {
  height: 36px;
}
.custom-mat-cell {
  padding: 8px; /* Adjust the padding as needed */
}

.custom-mat-table .mdc-data-table__row {
  height: 32px;
}

.custom-mat-table .mdc-data-table__cell, .mdc-data-table__header-cell {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #e9ecef;
  font-size: 13px;
}
.mat-mdc-paginator-container{
  margin-top:20px;
  }

  .mdc-fab--mini {
    width: 30px !important;
    height:30px !important;
    .material-icons {
      font-size: 21px !important;
      margin-top: 4px;
    }
  }

  .mat-mdc-menu-content{
    min-height: 0 !important;
    padding: 0 !important;
  }
  

  .mat-mdc-menu-item{
    min-height: 35px !important;
    .mdc-list-item__primary-text {
      font-size:14px !important;
    }
  }
  .mdc-list-item.mdc-list-item--with-one-line {
    height: 32px !important;
    .mdc-list-item__primary-text {
      font-size: 14px !important;
    }
  }

  .mat-mdc-autocomplete-panel{
    min-width: 170px;
    .mat-mdc-option{
      min-height:0 !important;
     
    }
  }
.emoji-mart{
  margin-bottom:52px !important;
}
.mandatory-padding{
  padding-left: 0px !important;
}
 