
// default colors
.theme-wrapper {

    --fontColor: #555;
    --theme-color1: #00235E;
    --theme-color2: #00235E;
    --headerColor: #fff;
    --headerBackground: #00235E;
    --LogofontColor:#fff;
    --LogospanColor:#00235E;
    --navColor: #00235E;
    --navBackground: #FFF;
    --navactiveColor: #00235E;
    --navactiveBackground: #d9d9d9;
    --sidebarColor:#00235E;
    --sidebarBackground:#fff;
    --sidebarActiveColor:#00235E;
    --sidebarActiveBackground:#d9d9d9;
    --buttonprimaryColor: #FFF;
    --buttonprimaryBackground: #00235E;
    --buttonprimaryactiveColor: #FFF;
    --buttonprimaryactiveBackground: #d9d9d9;
    --buttonsecondaryactiveColor: #00235E;
    --buttonsecondaryactiveBackground: #d9d9d9;
    --buttonsecondaryColor: #FFF;
    --buttonsecondaryBackground: #00235E;
    --tableheadColor: #00235E;
    --tableheadBackground: rgb(241, 235, 235);
    --tablealternateBackground: rgb(241, 243, 220);
    --footerColor: #fff;
    --footerBackground: #00235E;
  }
  
  $variables: (

    --fontColor: var(--fontColor),
    --theme-color1: var(--theme-color1),
    --theme-color2: var( --theme-color2),
    --headerColor: var(--headerColor),
    --headerBackground:var(--headerBackground),
    --LogofontColor: var(--LogofontColor),
    --LogospanColor: var(--LogospanColor),
    --navColor: var(--navColor),
    --navBackground:var(--navBackground),
    --navactiveColor:var(--navactiveColor),
    --navactiveBackground: var(--navactiveBackground),
    --sidebarColor:var(--sidebarColor),
    --sidebarBackground:var(--sidebarBackground),
    --sidebarActiveColor:var(--sidebarActiveColor),
    --sidebarActiveBackground:var(--sidebarActiveBackground),
    --buttonprimaryColor: var(--buttonprimaryColor),
    --buttonprimaryBackground: var(--buttonprimaryBackground),
    --buttonprimaryactiveColor: var(--buttonprimaryactiveColor),
    --buttonprimaryactiveBackground: var(--buttonprimaryactiveBackground),
    --buttonsecondaryactiveColor: var(--buttonsecondaryactiveColor),
    --buttonsecondaryactiveBackground: var(--buttonsecondaryactiveBackground),
    --buttonsecondaryColor: var(--buttonsecondaryColor),
    --buttonsecondaryBackground: var(--buttonsecondaryBackground),
    --tableheadColor: var(--tableheadColor),
    --tableheadBackground: var(--tableheadBackground),
    --tablealternateBackground: var(--tablealternateBackground),
    --footerColor: var(--footerColor),
    --footerBackground: var(--footerBackground),

  );